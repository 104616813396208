import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy ("Policy") describes how Alex Little ("Developer") collects, uses, and shares personal information of users of the Feels mobile application ("App").
      </p>
      <h2>Information We Collect</h2>
      <p>
        We may collect the following types of personal information when you use our App:
      </p>
      <ul>
        <li>Your name and email address, if you provide it to us</li>
        <li>Your device's IP address, operating system, and browser type</li>
        <li>Your usage of the App, including the features you use and the time spent on each feature</li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>
        We may use the personal information we collect for the following purposes:
      </p>
      <ul>
        <li>To provide and improve our App</li>
        <li>To communicate with you about the App and your use of it</li>
        <li>To personalize your experience with the App</li>
        <li>To understand how users use our App and to improve our App</li>
        <li>To comply with applicable laws and regulations</li>
      </ul>
      <h2>Sharing of Your Information</h2>
      <p>
        We may share your personal information with third parties only in the following circumstances:
      </p>
      <ul>
        <li>With your consent</li>
        <li>To comply with applicable laws and regulations</li>
        <li>To protect the rights or property of the Developer or its users</li>
        <li>In connection with a merger, acquisition, or sale of all or a portion of our assets</li>
      </ul>
      <h2>Security of Your Information</h2>
      <p>
        We take reasonable measures to protect your personal information from unauthorized access, use, and disclosure. However, no security measures are perfect and we cannot guarantee the security of your information.
      </p>
      <h2>Changes to This Policy</h2>
      <p>
        We reserve the right to modify this Policy at any time. If we make material changes to this Policy, we will notify you by email or by posting a notice in the App.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Policy or our privacy practices, please contact us at [Developer Contact Information].
      </p>
    </div>
  );
};

export default PrivacyPolicy;
