import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState, setInterval } from 'react';
import TermsOfService from './Terms';
import PrivacyPolicy from './Privacy';
import ReactDOM from 'react-dom';
import downloadButton from './download-button.svg';

function AppRoot() {
  return (
    <div className="background">
      <div className="container">
        <h1 className="title">Feels</h1>
        <h2 className="subtitle">Share Your Mood</h2>
        <a href="https://apps.apple.com/us/app/feels-share-your-mood/id1555035346" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
          <img src={downloadButton} alt="Download on the app store" style={{ width: '200px', height: '60px' }} />
      </a>
      </div>
    </div>
  );
}

function App() {
  const [route, setRoute] = useState(window.location.pathname);

  const handleRouteChange = () => {
    setRoute(window.location.pathname);
  };

  // Add event listener to update route state when URL changes
  window.addEventListener('popstate', handleRouteChange);

  let content;

  switch (route) {
    case '/':
      content = <AppRoot />;
      break;
    case '/terms':
      content = <TermsOfService />;
      break;
    case '/privacy':
      content = <PrivacyPolicy />;
      break;
    default:
      content = <AppRoot />;
  }

  return content;
}

export default App;
